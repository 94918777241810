@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sticky-top {
    @apply sticky top-1;
  }

  .soft-shrink {
    @apply hover:scale-[0.98] transition-all duration-300 cursor-pointer;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Satoshi', sans-serif;    
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */                   
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

::-webkit-scrollbar{
  display: none;
}

::-webkit-scrollbar-thumb {
  
  display: none;
}

.satoshi {
  font-family: 'Satoshi', sans-serif !important; 
}

.hide-scroll-bar::-webkit-scrollbar{
  display: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #F5F9F8 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.available-height{
  min-height: -webkit-fill-available;
}

.welcome-graphic svg g{
  scale: 10% !important;
  color: red;
}

.ani-slide-left{
  animation: slide-left 0.5s ease-in-out forwards;
}


@keyframes slide-left {
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(0px);
  }
}


.ani-rise{
  animation: rise 0.5s ease-in-out forwards;
}

@media (max-width: 767px) {
  @keyframes rise {
    0%{
      transform: translateY(100%);
    }
    100%{
      transform: translateY(0px);
    }
  }
}

@media (min-width: 768px) {
  @keyframes rise {
    0%{
      transform: translateY(50px);
    }
    100%{
      transform: translateY(0px);
    }
  }
}

.ani-drop{
  animation: drop 0.3s ease-in-out forwards;
}

@keyframes drop {
  0%{
    transform: translateY(-30px);
    opacity: 0%;
  }
  70%{
    opacity: 20%;
  }
  100%{
    transform: translateY(0px);
    opacity: 100%;
  }
}

.ani-shake{
  animation: shake 1s ease-in-out forwards;
}

@keyframes shake {
  0%{
    transform: rotate(3deg);
    opacity: 0%;
  }
  70%{
    transform: rotate(-3deg);
    opacity: 100%;
  }
  100%{
    transform: rotate(0deg);
    opacity: 100%;
  }
}


.react-datepicker__header {
    background-color: white !important;
    padding-top: 0px !important;
    border: none !important;
  }

.react-datepicker__day-name {
  color: #c7c7c7 !important;
  font-size: 14px !important;
}

.react-datepicker__day {
  color: #02291C !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background: #077A55 !important;
  color: white !important;
}
.react-datepicker__day--disabled {
  color: #c7c7c7 !important;
}


.react-datepicker-time__input{
  font-size: 12px;
  min-width: 200px;
  background-color: #f5f9f8;
  padding: 4px;
  border-radius: 4px;
  margin: auto;
}

.react-datepicker-time__caption{
  display: none !important;
}

.react-datepicker__input-time-container{
  margin: 5px 0px 0px 0px !important;
}

.react-datepicker-time__input-container{
  /* border: 1px solid gray; */
  /* margin-left: 4px; */
  border-radius: 4px;
  margin: 0px !important;
  padding: 0px !important;
}

input[type="time"]:focus{
  outline: none;
  border: none;
}

input[type="time"]::-webkit-calendar-picker-indicator:hover {
  background-color: transparent;
}

input[type="time"]::-webkit-calendar-picker-indicator:focus {
  background-color: transparent;
}

.coin-1{
  animation: coin-rise 1s infinite alternate;
  animation-delay: 0ms;
}

.coin-2{
  animation: coin-rise 1s infinite alternate;
  animation-delay: 200ms;
}

.coin-3{
  animation: coin-rise 1s infinite alternate;
  animation-delay: 400ms
}

@keyframes coin-rise {
  0%{
    transform: translateY(0px);
  }
  50%{
    transform: translateY(-60px);
  }
  100%{
    transform: translateY(0px);
  }
}
